import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { PublicService } from '../services/public.service';

@Component({
  selector: 'app-final-screen',
  templateUrl: './final-screen.component.html',
  styleUrls: ['./final-screen.component.scss'],
})
export class FinalScreenComponent implements OnInit {
  constructor(
    public apiService: ApiService,
    public publicService: PublicService
  ) {}

  ngOnInit(): void {
    this.saveBookingDetailsInDB();
    this.sendDataInWebhook();
  }

  saveBookingDetailsInDB() {
    const bookData = this.publicService.globalBookingDetailsSaverData;
    this.apiService.saveBookingDetailsInDb(bookData).subscribe((res: any) => {
      console.log('Booking data saved');
    });
  }

  sendDataInWebhook() {
    const bookData = this.publicService.globalWebhookData;
    this.apiService.createWebhook(bookData).subscribe((res: any) => {
      console.log('Webhook data sent');
    });
  }
}
