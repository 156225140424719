export const environment = {
  production: true,
  // apiUrl: 'https://lga1h6u8ai.execute-api.us-east-1.amazonaws.com/Dev/v2',
  apiUrl:
    'https://40ljn42p3d.execute-api.us-east-1.amazonaws.com/prod/v2',
  zipUrl: '',
  siteKey: '6Le9-CwkAAAAAKzfS1UrTpwsZFusgvNugDo68aBO',
  attributeKey: '2b99589e-c777-49be-9e19-072252a69194',
  teamMemberid: 'TEAM_MEMBER_ID',
  teamMemberid_new: ' ',
  locationId: 'LH1KYQ79GEVFE',
  webhookUrl: 'https://n5n2k6ysp0.execute-api.us-east-1.amazonaws.com/prod/htgapp/notify/prod',
  autocompleteUrl:
    'https://3fveb9l6s3.execute-api.us-east-1.amazonaws.com/TestAtuh/aws',
  afterBookingApiUrl:
    'https://0j9idt3tzd.execute-api.us-east-1.amazonaws.com/prod/',
  webURL: 'https://app.hometechgeeks.com/',
  zipMultipleTechnician : 'https://3h1z189bse.execute-api.us-east-1.amazonaws.com/prod/zipCodeInfoWithGoogleProd',
  request_type: 'PROD',
  request_from: 'autocompletebetadev',
  timeSlotsAvailaility : 'https://5svcmxgcci.execute-api.us-east-1.amazonaws.com/prod/fetchTechniciansTimeslot',
  bookingDetailsSaverDB : 'https://l7lj0lnrze.execute-api.us-east-1.amazonaws.com/dev/savebookingdetails',
};
