import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  latestRandomCode: string;
  latestRandomNumber: string;
  constructor(private http: HttpClient) {}
  randomNumberGenerator(min: number, max: number) {
    var minm = min;
    var maxm = max;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  }
  randomNumber = this.randomNumberGenerator(100, 999);
  elevenRandomNumber = this.randomNumberGenerator(10000000000, 99999999999);
  string = 'method';
  passParameters(method: any, pathname: any, methodShortname: string) {
    this.latestRandomCode =
      'bWFw=' +
      this.randomNumber +
      btoa(pathname) +
      `${this.elevenRandomNumber}` +
      methodShortname +
      '&bWV0aG9k=' +
      btoa(method);
    return (
      'bWFw=' +
      this.randomNumber +
      btoa(pathname) +
      `${this.elevenRandomNumber}` +
      methodShortname +
      '&bWV0aG9k=' +
      btoa(method)
    );
  }

  getCatalogList(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/list/catalog`);
  }

  createCustomer(data: {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/customers`,data);
  }

  updateCustomer(data: {}, customerId: any): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/customers/${customerId}`,data);
  }

  // searchAvailability(data: {}): Observable<any> {
  //   return this.http.post(
  //     `${environment.apiUrl}` +
  //       this.passParameters('POST', 'bookings/availability/search', 'P'),
  //     data
  //   );
  // }

  timeSlotsAvailability(data : {}): Observable<any>{
    return this.http.post(`${environment.timeSlotsAvailaility}`, data);
  }
  
  bookAppointment(data: {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/bookings`,data);
  }

  zipValidateApi(data: {}): Observable<any> {
    return this.http.post(`${environment.zipMultipleTechnician}`, data);
  }

  autoCompleteApi(data: {}): Observable<any> {
    return this.http.post(`${environment.autocompleteUrl}/autocomplete`, data);
  }

  createWebhook(data) {
    return this.http.post(`${environment.webhookUrl}`, data);
  }

  getTeamMemberDetails(teamMemberid: string) {
    teamMemberid;
    return this.http.get(
      `${environment.apiUrl}` +'/team-members/' + teamMemberid
    );
  }
  saveBookingDetailsInDb(data : {}): Observable<any>{
    return this.http.post(`${environment.bookingDetailsSaverDB}`, data);
  }
}
