import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from './services/public.service';
// Declare the 'google' namespace
declare var google: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'home-tech';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public publicService: PublicService
  ) {
    if (this.router.url == '/') {
      this.router.navigateByUrl('/home/1');
    }
  }

  ngOnInit() {
    // this.publicService.show
    // const input = document.getElementById('location-input') as HTMLInputElement;
    // const autocomplete = new google.maps.places.Autocomplete(input);

    // autocomplete.addListener('place_changed', () => {
    //   const place = autocomplete.getPlace();
    //   // Handle the selected place, e.g., place.name, place.geometry.location, etc.
    // });
  }
  // 2b99589e-c777-49be-9e19-072252a69194 prod id
  // 4a8c0e16-e9ff-4ad9-8dc9-03e6f24aec1b local id

  // ngOnInit() {
  //   // this.loadGoogleMaps();
  // }

  // loadGoogleMaps() {
  //   const script = document.createElement('script');
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCntVJYFTyAa0N0JACpZ63gbBL64-czSLI&libraries=places&callback=initMap`;
  //   script.defer = true;
  //   script.async = true;

  //   // Define the callback function
  //   window['initMap'] = () => {
  //     this.initMap();
  //   };

  //   document.head.appendChild(script);
  // }

  // initMap() {
  //   const input = document.getElementById('location-input') as HTMLInputElement;
  //   const autocomplete = new google.maps.places.Autocomplete(input);

  //   autocomplete.addListener('place_changed', () => {
  //     this.zone.run(() => {
  //       const place = autocomplete.getPlace();
  //       console.log('Selected Place:', place);
  //       // Manipulate the selected value here as needed
  //     });
  //   });
  // }

}
