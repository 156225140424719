import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  showLoading: boolean = true;
  globalBookingDetailsSaverData: any;
  globalWebhookData: any;
  constructor(private spinnerService: NgxSpinnerService) {}

  showSpinner() {
    // this.showLoading = true;
    this.spinnerService.show();
  }

  hideSpinner() {
    // this.showLoading = true;
    this.spinnerService.hide();
  }

  scrollToView(el: Element) {
    el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }

  scrollToInvalidField(formref, selector: string) {
    const firstInvalidField = formref.nativeElement.querySelector(selector);
    console.log(firstInvalidField);
    if (firstInvalidField) {
      firstInvalidField.scrollIntoView({ behavior: 'smooth' });
    }
  }

  pushCustomEventsToZoho(customEventName: string) {
    (window as any).pagesense = (window as any).pagesense || [];
    (window as any).pagesense.push(['trackEvent', customEventName]);
  }

  // Filter Unique objects on the basis of it property
  filterUniqueObjectsWithDuplicates(array: any[], property: string) {
    const seen = new Map();
    const result = [];

    array.forEach((item) => {
      const propertyValue = item[property];
      if (!seen.has(propertyValue)) {
        seen.set(propertyValue, item);
      } else {
        // If start_at value already exists, append technician_id to the existing entry
        const existingItem = seen.get(propertyValue);
        if (Array.isArray(existingItem.technician_id)) {
          existingItem.technician_id.push(item.technician_id);
        } else {
          existingItem.technician_id = [
            existingItem.technician_id,
            item.technician_id,
          ];
        }
      }
    });

    seen.forEach((value) => {
      result.push(value);
    });

    return result;
  }

  // Find closest technician
  getClosestTechnician(input: string | string[], technicianList : any[]): string {
    if (typeof input === 'string') {
        // If input is a string, return the input
        return input;
    } else if (Array.isArray(input)) {
        // If input is an array, find the technician with the minimum distance
        let minDistance = Infinity;
        let closestTechnicianId = '';

        input.forEach(id => {
            const technician = technicianList.find(t => t.TechnicianId === id);
            if (technician && technician.Distance < minDistance) {
                minDistance = technician.Distance;
                closestTechnicianId = id;
            }
        });

        return closestTechnicianId;
    } else {
        // Invalid input type
        throw new Error('Invalid input type. Input must be a string or an array.');
    }
}

}
